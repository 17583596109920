import IMG_logo from './../assets/img/cerrawall-logo.png';
import IMG_intro from './../assets/img/bg.jpg';
import IMG_img1 from './../assets/img/img1.jpg';
import IMG_front from './../assets/img/front.jpg';
import IMG_city from './../assets/img/city.jpg';
import IMG_idea from './../assets/img/idea.jpg';
import IMG_collage from './../assets/img/collage.jpg';
import IMG_gallery1 from './../assets/img/5.jpg';
import IMG_gallery2 from './../assets/img/6.jpg';
import IMG_gallery3 from './../assets/img/7.jpg';

import T_01A from './thumbnails/01a.jpg';
import T_02A from './thumbnails/02a.jpg';
import T_03A from './thumbnails/03a.jpg';
import T_04A from './thumbnails/04a.jpg';
import T_05A from './thumbnails/05a.jpg';
import T_06A from './thumbnails/06a.jpg';
import T_07A from './thumbnails/07a.jpg';
import T_08A from './thumbnails/08a.jpg';
import T_09A from './thumbnails/09a.jpg';
import T_10A from './thumbnails/10a.jpg';
import T_11A from './thumbnails/11a.jpg';
import T_12A from './thumbnails/12a.jpg';
import T_13A from './thumbnails/13a.jpg';
import T_14A from './thumbnails/14a.jpg';
import T_15A from './thumbnails/15a.jpg';
import T_16A from './thumbnails/16a.jpg';
import T_17A from './thumbnails/17a.jpg';

import P_01A from './prods/01a.jpg';
import P_02A from './prods/02a.jpg';
import P_03A from './prods/03a.jpg';
import P_04A from './prods/04a.jpg';
import P_05A from './prods/05a.jpg';
import P_06A from './prods/06a.jpg';
import P_07A from './prods/07a.jpg';
import P_08A from './prods/08a.jpg';
import P_09A from './prods/09a.jpg';
import P_10A from './prods/10a.jpg';
import P_11A from './prods/11a.jpg';
import P_12A from './prods/12a.jpg';
import P_13A from './prods/13a.jpg';
import P_14A from './prods/14a.jpg';
import P_15A from './prods/15a.jpg';
import P_16A from './prods/16a.jpg';
import P_17A from './prods/17a.jpg';

const img: any = {
    logo: IMG_logo,
    intro: IMG_intro,
    img1: IMG_img1,
    front: IMG_front,
    city: IMG_city,
    idea: IMG_idea,
    collage: IMG_collage,
    gallery1: IMG_gallery1,
    gallery2: IMG_gallery2,
    gallery3: IMG_gallery3,

    thumb01: T_01A,
    thumb02: T_02A,
    thumb03: T_03A,
    thumb04: T_04A,
    thumb05: T_05A,
    thumb06: T_06A,
    thumb07: T_07A,
    thumb08: T_08A,
    thumb09: T_09A,
    thumb10: T_10A,
    thumb11: T_11A,
    thumb12: T_12A,
    thumb13: T_13A,
    thumb14: T_14A,
    thumb15: T_15A,
    thumb16: T_16A,
    thumb17: T_17A,

    prod01: P_01A,
    prod02: P_02A,
    prod03: P_03A,
    prod04: P_04A,
    prod05: P_05A,
    prod06: P_06A,
    prod07: P_07A,
    prod08: P_08A,
    prod09: P_09A,
    prod10: P_10A,
    prod11: P_11A,
    prod12: P_12A,
    prod13: P_13A,
    prod14: P_14A,
    prod15: P_15A,
    prod16: P_16A,
    prod17: P_17A,
};

export default img;
