import { ProductsState, ProductsActionTypes, LOAD_PRODUCTS } from './types';

const initialState: any = [];

export const productsReducer = (state = initialState, action: ProductsActionTypes): ProductsState => {
    switch (action.type) {
        case LOAD_PRODUCTS: {
            return [...state, action.payload];
        }

        default:
            return state;
    }
};
