import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import MainLayout from '../layouts/MainLayout';

import DividerLine from '../components/shared/DividerLine';
import img from './../assets/Images';
import Card from './../components/shared/Card';

const Delivery: FunctionComponent = () => {
    return (
        <MainLayout>
            <Wrapper>
                <h1>Доставка</h1>

                <DividerLine />

                <p>Страницата в момента се обновява. Моля да ни извините.</p>

                <DividerLine />

                {/* <Card
                    heading="Срок за доставка"
                    content={`Доставката се извършва между 7 и 15 дни след получена заявка и потвърждаване на поръчката по
                    телефон. Обикновено Ви се обаждаме 2 дни предварително да уточним детайлите. Доставката се осъществява или чрез куриерска фирма, или чрез наш транспорт.`}
                /> */}

                {/* <Card
                    heading="Цена на доставка"
                    content={`Цената за доставка на тухли е фиксирана и е 50 лв. до посочен от вас адрес, която се заплаща при получаването на продуктите.`}
                /> */}

                {false && (
                    <Card
                        heading="Счупени продукти при доставка"
                        content={`При транспорта съществува вероятност да има счупени тухли и можем да ви възстановим парите или да заменим
                    счупените тухли за нови, освен ако не е опомената, че продукта съдържа счупени, напукани или нецели
                    тухли, или техния процент е повече от половината. Това може да се случи единствено ако имате
                    доказателства (най-често снимки), като доставчика трябва да бъде свидетел.`}
                    />
                )}

                <img alt="cerrawall logo" src={img.logo} />
            </Wrapper>
        </MainLayout>
    );
};

const Wrapper = styled.div`
    h1 {
        text-align: center;
        padding-top: 20px;
    }

    h1,
    h3,
    h4 {
        font-weight: 100;
    }

    a {
        color: #d87667;
        font-weight: 400;
    }

    img {
        width: 100%;
        height: auto;
    }
`;

export default Delivery;
