import React, { useState } from 'react';
import styled from 'styled-components/macro';

import PRODUCTS_LIST, { ProductsType } from 'src/services/productsList';

import ProductTile from 'src/components/products/ProductTile';
import Navigation from 'src/components/Navigation';
import Footer from 'src/components/Footer';

const Products = () => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchResults, setSearchResults] = useState<any[]>([]);

    React.useEffect(() => {
        const found = PRODUCTS_LIST.filter(
            (p) => (p.id.includes(searchValue) || p.name.includes(searchValue)) && p.productType === 'brick'
        );
        setSearchResults(found);
    }, [searchValue]);

    return (
        <Wrapper>
            <Navigation />

            <Grid>
                <SearchBox>
                    <Input
                        type="text"
                        value={searchValue}
                        name="search"
                        placeholder="Търси по име или код"
                        onChange={(e) => setSearchValue(e.currentTarget.value)}
                    />
                </SearchBox>
                <ProductsWrapper>
                    {searchValue
                        ? searchResults.map((product: ProductsType) => {
                              if (product.productType === 'brick') {
                                  return <ProductTile product={product} key={product.id} />;
                              }

                              return null;
                          })
                        : PRODUCTS_LIST.map((product: ProductsType) => {
                              if (product.productType === 'brick') {
                                  return <ProductTile key={product.id} product={product} />;
                              }

                              return null;
                          })}

                    {searchValue !== '' && searchResults.length < 1 && <h1>Не са намерени резултати</h1>}
                </ProductsWrapper>
            </Grid>

            <Footer />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    min-height: calc(100vh - 80px); // -navigation
`;

const Grid = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 960px;
    margin: 80px auto 0 auto;
    padding: 0 10px;
    padding-bottom: 300px; // footer height
`;

const SearchBox = styled.div`
    width: 100%;
    height: 80px;
`;

const Input = styled.input`
    padding: 10px;
    margin-top: 18px;
    width: 100%;
`;

const ProductsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
`;

export default Products;
