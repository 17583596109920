import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import MainLayout from '../layouts/MainLayout';

import DividerLine from '../components/shared/DividerLine';
import img from '../assets/Images';

const TosAndPp: FunctionComponent = () => {
    const EMAIL = process.env.REACT_APP_EMAIL;
    return (
        <MainLayout>
            <Wrapper>
                <h1>Политика за поверителност</h1>

                <p>
                    Защитата на личните данни е от голямо значение за CERRAWALL и искаме процесът по обработка на Вашите
                    лични данни да бъде напълно открит и прозрачен за Вас. Ето защо имаме политика, която определя как
                    ще бъдат обработвани и защитени Вашите лични данни.
                </p>

                <DividerLine />

                <h3>Кой носи отговорност за личните Ви данни?</h3>

                <p>
                    Българската компания INTELLITHINKER (наричана по-долу с търговското име “CERRAWALL”) е администратор
                    на личните данни, които ни предоставяте и носи отговорност за тях, съгласно приложимия закон за
                    защита на личните данни.
                </p>

                <h4>INTELLITHINKER</h4>
                <h4>Еднолично дружество с ограничена отговорност (ЕООД)</h4>
                <h4>ЕИК: 204837577</h4>

                <DividerLine />

                <h3>Къде съхраняваме Вашите данни?</h3>

                <p>
                    Данните, които събираме от Вас, се съхраняват в рамките на Европейското икономическо пространство
                    ("ЕИП"), но също така могат да бъдат прехвърлени и обработвани в страна извън ЕИП. Всеки случай на
                    прехвърляне на Вашите лични данни ще се извършва в съответствие с приложимите закони. За преводи
                    извън ЕИП CERRAWALL ще използва Стандартните договорни клаузи и Рамката за защита на личните данни в
                    отношенията между ЕС и САЩ като гаранции за държавите, по отношение на които липсва подходящо
                    решение от страна на Европейската комисия.
                </p>

                <DividerLine />

                <h3>Кой има достъп до Вашите данни?</h3>

                <p>
                    Единствено CERRAWALL има достъп да администрира вашите данни. Ние никога не прехвърляме, продаваме
                    или разменяме Вашите данни за маркетингови цели на трети страни. Данните, които се препращат на
                    трети страни, се използват само за предоставяне на нашите услуги. Запознайте се с категориите трети
                    страни за всеки процес по-долу.
                </p>

                <DividerLine />

                <h3>Какво е правното основание за обработването на Вашите данни?</h3>

                <p>
                    За всяко конкретно обработване на лични данни, които събираме от Вас, ще Ви информираме дали
                    предоставянето на лични данни се изисква от закона или е необходимо за сключване на договор и дали
                    сте задължени да предоставяте лични данни, както и какви последици са възможни, ако решите да не го
                    направите.
                </p>

                <DividerLine />

                <h3>Какви са Вашите права?</h3>

                <h4>Право на достъп: </h4>
                <p>
                    По всяко време имате право да поискате информация относно личните Ви данни, които съхраняваме.
                    Можете да се свържете се с нас, след което Вашите лични данни ще Ви бъдат предоставени чрез
                    електронна поща.
                </p>

                <h4>Право на преносимост: </h4>
                <p>
                    Когато CERRAWALL обработва Вашите лични данни по автоматизиран начин въз основа на Вашето съгласие
                    или въз основа на споразумение, имате право да получите копие от Вашите данни в структуриран, широко
                    използван и пригоден за машинно четене формат, прехвърлени на Вас или на друга страна. Това включва
                    само личните данни, които сте ни предоставили.
                </p>

                <h4>Право на коригиране: </h4>
                <p>
                    Имате право да поискате коригиране на Вашите лични данни, ако те са неправилни, включително
                    допълване на непълни лични данни.{' '}
                </p>

                <h4>Право да възразите срещу обработването въз основа на легитимен интерес: </h4>
                <p>
                    Имате право да възразите срещу обработването на Вашите лични данни въз основа на легитимния интерес
                    на CERRAWALL. CERRAWALL няма да продължи да обработва личните Ви данни, освен ако не се докаже, че
                    съществуват убедителни правни основания за това, които имат предимство пред интересите и правата Ви
                    или поради съдебни искове.
                </p>

                <h4>
                    Имате право да поискате CERRAWALL да ограничи обработването на Вашите лични данни при следните
                    обстоятелства:
                </h4>
                <p>
                    *ако възразите срещу обработването въз основа на легитимен интерес на CERRAWALL, CERRAWALL ще
                    ограничи обработването на данните в очакване на резултат от проверката на законните основания. *ако
                    претендирате, че Вашите данни са неточни, CERRAWALL трябва да ограничи обработването в очакване на
                    резултат от проверката на точността на личните данни. *ако обработването е неправомерно, можете да
                    възразите срещу изтриването на личните Ви данни и вместо това да изискате ограничаване на
                    използването им *ако CERRAWALL вече не се нуждае от личните данни, но те са Ви необходими за
                    упражняване или защита на правни претенции.
                </p>

                <h4>Как можете да приложите Вашите права?</h4>
                <p>
                    Ние се отнасяме много сериозно към защитата на личните данни. Винаги можете да се свържете с на
                    {EMAIL}
                </p>

                <h4>Право на подаване на жалба към надзорен орган:</h4>
                <p>
                    Ако смятате, че CERRAWALL обработва Вашите лични данни по неправилен начин, можете да се свържете с
                    нас. Също така имате право да подадете жалба към надзорен орган.
                </p>

                <h4>Актуализиране на нашата Политика за поверителност:</h4>
                <p>
                    Възможно е да актуализираме нашата Политика за поверителност. Най-новата версия винаги е достъпна на
                    нашия уебсайт. Ще съобщим всички съществени промени в Политиката за поверителност, например целта на
                    използването на личните Ви данни, самоличността на администратора или Вашите права.
                </p>

                <DividerLine />

                <h1>Пазаруване онлайн</h1>

                <h3>Защо използваме Вашите лични данни?</h3>

                <p>
                    Ние използваме Вашите лични данни, за да Ви предоставим информацията, продуктите и услугите, които
                    сте заявили или закупили от нас онлайн и в нашите магазини. По-конкретно, използваме Вашите лични
                    данни за обработка на Вашата поръчка и плащане, за издаване на електронни касови бележки,
                    обработване на доставки, връщания и гаранционни искове за продуктите.
                </p>
                <p>
                    При всяко Ваше плащане, връщане на продукт или кандидатстване за финансова услуга ние използваме
                    Вашите лични данни, за да потвърдим самоличността Ви и правоспособността Ви за сключване на
                    договори, дали финансовата информация, която ни предоставяте, е точна, за проверка на
                    кредитоспособността Ви, за извършване на проверки за измама или за предотвратяване на друга
                    незаконна дейност.
                </p>
                <p>
                    Ако изискате фактура с ДДС или касова бележка с ДДС от нас, ще Ви помолим да ни предоставите
                    определени лични данни, които да обработим, за да изпълним Вашата заявка.
                </p>
                <p>
                    Вашите лични данни се използват, за да Ви идентифицират, да валидират предвидената от закона възраст
                    за онлайн пазаруване, както и за да потвърдят адреса Ви пред външни партньори.{' '}
                </p>

                <DividerLine />

                <h3>Какви лични данни обработваме?</h3>
                <p>
                    Ще обработваме следните категории лични данни * информация за контакт, например име, адрес, имейл и
                    телефонен номер * информация и история на плащанията * кредитна информация * информация за поръчките
                    *социално-осигурителен номер, личен идентификационен номер, номер на паспорт, номер по ДДС или
                    подобен идентификационен номер (ако решите да ни го предоставите). Ако имате профил в CERRAWALL, ще
                    обработваме и Вашите лични данни, предоставени във връзка с профила, като например: * номер на
                    профил * история на пазаруването{' '}
                </p>

                <DividerLine />

                <h3>Кой има достъп до Вашите лични данни?</h3>
                <p>
                    Вашите лични данни понякога се споделят с трети страни, което ни помага да извършим определени
                    задачи или да предоставим заявена услуга. По-специално, CERRAWALL може да потърси трети страни за
                    проверка на Вашия адрес, комуникационни агенции, за да Ви изпрати потвърждение за поръчка, складове
                    и доставчици във връзка с доставката на Вашата поръчка, доставчици на платежни услуги за обработване
                    на Вашите плащания с кредитни/дебитни карти, банки и други доставчици на финансови услуги, канали в
                    социалните мрежи, ако комуникирате с нас чрез отбелязване/споменаване или ако се свържете директно с
                    нас.
                </p>
                <p>
                    Моля, имайте предвид, че много от тези компании имат независимо право или задължение да обработват
                    Вашите лични данни.
                </p>

                <DividerLine />

                <h3>Какво е правното основание за обработване на Вашите лични данни?</h3>
                <p>
                    Когато Ви предоставя продукти и услуги, CERRAWALL ще обработва Ваши лични данни, необходими за
                    сключването на договор с Вас и за изпълнение на всички задължения, произтичащи от този договор,
                    независимо дали договорът се отнася до направена поръчка, плащане, или за използване на други
                    услуги, предоставяни от нас или трети страни.
                </p>
                <p>
                    Ако сте се регистрирали за получаване на електронни касови бележки, обработката на Вашите лични
                    данни, необходими за тази цел, се основава на Вашето съгласие.
                </p>
                <p>
                    Във връзка с предоставянето на нашите продукти и услуги понякога сме законово задължени да събираме,
                    обработваме и съхраняваме Вашите лични данни, например с цел спазване на приложимите данъчни и
                    финансови закони.
                </p>

                <DividerLine />

                <img alt="cerrawall logo" src={img.logo} />
            </Wrapper>
        </MainLayout>
    );
};

const Wrapper = styled.div`
    h1 {
        text-align: center;
        padding-top: 20px;
    }

    img {
        width: 100%;
        height: auto;
    }
`;

export default TosAndPp;
