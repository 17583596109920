export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';

export type ProductsState = any;

type LoadProductsAction = {
    type: typeof LOAD_PRODUCTS;
    payload: ProductsState;
};

export type ProductsActionTypes = LoadProductsAction;
