import React, { FunctionComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

import { AppState } from 'src/store/index';
import { addQuantity } from 'src/store/cart/actionst';
import PRODUCTS_LIST from 'src/services/productsList';

import { breakpoints } from 'src/config';
import InfoCard from 'src/components/shared/InfoCard';
import RandomProducts from 'src/components/RandomProducts';
import DividerLine from 'src/components/shared/DividerLine';

import MainLayout from '../layouts/MainLayout';
import img from 'src/assets/Images';

const mapStateToProps = (state: AppState) => ({
    cart: state.cart,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ addQuantity }, dispatch);

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const ProductDetails: FunctionComponent<Props> = () => {
    const { productId } = useParams();

    const product = PRODUCTS_LIST.find((product) => product.id === productId);
    const sampleProduct = product && PRODUCTS_LIST.find((prod) => prod.id === product.sampleId);

    const thumb = product?.photos[0];
    const photo = img[thumb];

    return (
        <MainLayout>
            <Name>{product?.name}</Name>
            <ImageWrapper>
                <Image as={NavLink} to={`/products/${productId}/photos`} thumbnail={photo} />
            </ImageWrapper>

            <DividerLine />

            <Heading>Информация</Heading>

            <InfoCard>
                Код: {product!.id} / {product?.name}
            </InfoCard>
            {false && (
                <>
                    <InfoCard>
                        Един кашон съдържа {product?.details?.pcsPerBox} тухли, които с 1 см фуга покриват площ от{' '}
                        {product?.details?.boxCoversWithJoint} m2.
                    </InfoCard>
                    <InfoCard>
                        Размери: височина - <strong>{product?.details?.size.height}</strong> см; широчина -{' '}
                        <strong>{product?.details?.size.width}</strong> см; дебелина -{' '}
                        <strong>{product?.details?.size.thickness}</strong> см
                    </InfoCard>
                </>
            )}

            {false && (
                <InfoCard>
                    Един квадратен метър (с {product?.details?.boxCoversWithJoint}см фуга) се състои от{' '}
                    {product?.details?.pcsPerBox} бройки.
                </InfoCard>
            )}

            <InfoCard>
                Размери: височина - <strong>{product?.details?.size.height}</strong> см; широчина -{' '}
                <strong>{product?.details?.size.width}</strong> см; дебелина -{' '}
                <strong>{product?.details?.size.thickness}</strong> см
            </InfoCard>

            <DividerLine />

            {false && (
                <>
                    <Heading>Описание</Heading>
                    <InfoCard>{product?.details?.description}</InfoCard>

                    <DividerLine />
                </>
            )}

            <Heading>Техническа детайли</Heading>
            <InfoCard>{product?.details?.technicalDetails}</InfoCard>

            <DividerLine />

            {product?.details?.additionalInformation && (
                <>
                    <Heading>Допълнителна информация</Heading>

                    {product?.details?.additionalInformation.map((info, index) => {
                        return <InfoCard key={index}>{info}</InfoCard>;
                    })}

                    <DividerLine />
                </>
            )}

            <Heading>Мостри</Heading>

            {false ? (
                <SamplesWrapper>
                    <SampleImageWrapper>
                        <SampleImage img={sampleProduct?.photos[0]} />
                    </SampleImageWrapper>
                    <SampleDescription>
                        <SampleText>
                            Съветваме Ви предварително да поръчате мостри, за да добиете по-добра представа от
                            размерите, формите, цветовете и повърхността.
                        </SampleText>
                    </SampleDescription>
                </SamplesWrapper>
            ) : (
                <InfoCard>
                    Съветваме Ви предварително да поръчате мостри, за да добиете по-добра представа от размерите,
                    формите, цветовете и повърхността.
                </InfoCard>
            )}

            <DividerLine />

            <Heading>Други продукти</Heading>
            <RandomProducts />
        </MainLayout>
    );
};

const Heading = styled.h1`
    color: #555;
    font-weight: 400;

    @media ${breakpoints.tablet} {
        font-size: 20px;
    }
`;

const Name = styled(Heading)`
    padding-top: 30px;
    text-align: center;
    padding-bottom: 15px;
    font-weight: 300;
`;

const ImageWrapper = styled.div`
    position: relative;
    height: 0;
    box-sizing: border-box;
    overflow: hidden;
    width: 80%;
    padding-bottom: 52%;
    margin-left: auto;
    margin-right: auto;
`;

const Image = styled.div<{ thumbnail: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    cursor: pointer;
    background: url(${({ thumbnail }) => thumbnail});
    background-size: cover;
    background-position: center;
`;

const SamplesWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;

    @media ${breakpoints.tablet} {
        flex-direction: column;
    }
`;

const SampleImageWrapper = styled.div`
    flex: 0 0 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SampleImage = styled.div<{ img: string }>`
    width: 200px;
    height: 200px;
    border-radius: 10px;
    background: url(${({ img }) => img});
    background-position: center;
    background-size: cover;
`;

const SampleDescription = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #444444;
`;

const SampleText = styled.p`
    color: white;
    padding: 0 30px 20px 30px;
    line-height: 25px;
    letter-spacing: 1px;
    font-weight: 300;
`;

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
