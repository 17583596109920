import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import { breakpoints } from './../../config';

const InfoCard: FunctionComponent = ({ children }) => {
    return (
        <Wrapper>
            <ContentWrapper>
                <Text>{children}</Text>
            </ContentWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: inline-block;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 10px 30px;
    background: #f1f1f1;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px 0;
    box-sizing: border-box;
    margin-bottom: 10px;

    &:hover {
        background: #eee;
    }

    @media ${breakpoints.smallDesktop} {
        padding: 0;
        box-shadow: none;
        background: #fff;
        border-bottom: 1px solid #ddd;
        flex-direction: column;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;

    @media ${breakpoints.tablet} {
        flex-direction: column;
    }
`;

const Text = styled.p`
    line-height: 22px;
    letter-spacing: 0.5px;
    word-spacing: 1.2px;

    @media ${breakpoints.smallDesktop} {
        font-size: 13px;
    }
`;

export default InfoCard;
