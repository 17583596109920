import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import MainLayout from '../layouts/MainLayout';

import DividerLine from '../components/shared/DividerLine';
import img from '../assets/Images';

const Stores: FunctionComponent = () => {
    return (
        <MainLayout>
            <Wrapper>
                <h1>Магазини</h1>
                <h3>
                    Списък с магазините в България, в които можете да откриете някои модели от декоративните облицовки
                    на Cerrawall.
                </h3>

                <DividerLine />

                <h1>Бургас</h1>
                <h3>Практикер - Бургас</h3>
                <p>Адрес: Бургас, бул. "Янко Комитов" 8</p>
                <p>Телефон: 056/ 896 120</p>

                <DividerLine />

                <h1>Варна</h1>
                <h3>Практикер - Варна</h3>
                <p>Адрес: Варна, бул. "Република" 49</p>
                <p>Телефон: 052/ 579 120</p>

                <DividerLine />

                <h1>Плевен</h1>
                <h3>Практикер - Плевен</h3>
                <p>Адрес: Плевен, бул. "Европа" 6В</p>
                <p>Телефон: 064/ 887 120</p>

                <DividerLine />

                <h1>София</h1>
                <h3>Практикер - София Цариградско шосе</h3>
                <p>Адрес: София, бул. "Цариградско шосе" 323</p>
                <p>Телефон: 02/ 817 01 20</p>

                <DividerLine />

                <h3>Практикер - София Люлин</h3>
                <p>Адрес: София, бул. "Панчо Владигеров" 75</p>
                <p>Телефон: 02/ 810 71 20</p>

                <DividerLine />

                <img alt="cerrawall logo" src={img.logo} />

                <DividerLine />

                <h1>Партньорство</h1>
                <h3>
                    Ако желаете и вие да сте част от партньорите на Cerrawall и вашият магазин да присъства в този
                    списък, свържете се с нас и ще обсъдим детайлите и условията!
                </h3>
            </Wrapper>
        </MainLayout>
    );
};

const Wrapper = styled.div`
    h1 {
        // text-align: center;
        padding-top: 20px;
    }

    h1,
    h3,
    h4 {
        font-weight: 100;
    }

    a {
        color: #d87667;
        font-weight: 400;
    }

    img {
        width: 100%;
        height: auto;
    }
`;

export default Stores;
