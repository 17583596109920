import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

import img from '../assets/Images';
import { breakpoints } from 'src/config';

type Props = {
    children: any;
};

const HomeLayout: FunctionComponent<Props> = ({ children }) => {
    return (
        <MainWrapper>
            <Navigation />

            <Intro>
                <IntroInner>
                    <Heading1>CERRAWALL</Heading1>
                    <Heading2>декоративни тухли</Heading2>
                </IntroInner>
            </Intro>

            <Content>{children}</Content>
            <MainFooter />
        </MainWrapper>
    );
};
const MainWrapper = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    min-height: 100vh;
`;

const Intro = styled.div`
    position: relative;
    margin-top: 80px;
    width: 100%;
    height: 600px;
    background-color: #333;
    background-image: url(${img.intro});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    filter: grayscale(100%);

    @media ${breakpoints.tablet} {
        height: 260px;
    }
`;

const IntroInner = styled.div`
    position: relative;
    width: 100%;
    height: 600px;
    text-align: center;
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${breakpoints.tablet} {
        height: 260px;
    }
`;

const Heading1 = styled.h1`
    font-size: 68px;
    letter-spacing: 25px;
    line-height: 0;
    font-weight: 300;

    @media ${breakpoints.smallDesktop} {
        font-size: 38px;
        letter-spacing: 10px;
        padding-left: 10px;
    }
`;
const Heading2 = styled.h2`
    font-size: 26px;
    font-weight: lighter;
    letter-spacing: 7px;
    line-height: 0;

    @media ${breakpoints.smallDesktop} {
        font-size: 18px;
        letter-spacing: 8px;
        padding-left: 10px;
        word-spacing: 5px;
    }
`;

const Content = styled.div`
    max-width: 960px;
    margin: 20px auto 0 auto;
    padding: 0 10px;
    padding-bottom: 300px; // footer height
`;

const MainFooter = styled(Footer)``;

export default HomeLayout;
