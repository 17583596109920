import { combineReducers, applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import { translationsReducer } from './i18n/recuers';
import { cartReducer } from './cart/reducers';
import { productsReducer } from './products/reducers';

const rootReducer = combineReducers({
    translations: translationsReducer,
    cart: cartReducer,
    products: productsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const configureStore = () => {
    return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)));
};
