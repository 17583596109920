import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { AppState } from '../store/index';

const mapStateToProps = (state: AppState) => ({
    locale: state.translations.locale,
    messages: state.translations.messages,
});

const TranslationsProvider: FunctionComponent<ReturnType<typeof mapStateToProps>> = (props) => (
    <IntlProvider {...props} />
);

export default connect(mapStateToProps)(TranslationsProvider);
