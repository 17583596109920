import React, { useEffect, Fragment, FunctionComponent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface ScrollToTopProps extends RouteComponentProps<any> {
    // other props...
}

const ScrollToTop: FunctionComponent<ScrollToTopProps> = ({ history, children }) => {
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, [history]);

    return <Fragment>{children}</Fragment>;
};

export default withRouter(ScrollToTop);
