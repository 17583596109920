import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import { breakpoints } from './../../config';

type Props = {
    heading?: string;
    content?: string;
    icon?: any;
    actions?: any;
    props?: any;
};

const Card: FunctionComponent<Props> = ({ heading, content, icon, actions, props, children }) => {
    return (
        <Wrapper {...props}>
            <ContentWrapper>
                {children ? (
                    children
                ) : (
                    <>
                        {icon && <IconWrapper>{icon}</IconWrapper>}
                        <TextWrapper>
                            {heading && <Heading>{heading}</Heading>}
                            <Text>{content}</Text>
                        </TextWrapper>
                    </>
                )}
            </ContentWrapper>
            {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: inline-block;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 30px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px 0;
    box-sizing: border-box;
    border: 1px solid #ddd;
    margin-bottom: 30px;

    @media ${breakpoints.smallDesktop} {
        padding: 0;
        box-shadow: none;
        flex-direction: column;
        border: none;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;

    @media ${breakpoints.tablet} {
        flex-direction: column;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 30px;
`;

const TextWrapper = styled.div``;

const Heading = styled.h1`
    font-size: 22px;
    line-height: 34px;
    letter-spacing: 0.8px;
    word-spacing: 2px;
    margin: 0;
    font-weight: 500;

    @media ${breakpoints.smallDesktop} {
        font-size: 18px;
    }
`;

const Text = styled.p`
    line-height: 22px;
    letter-spacing: 0.5px;
    word-spacing: 1.2px;

    @media ${breakpoints.smallDesktop} {
        font-size: 13px;
    }
`;

const ActionsWrapper = styled.div`
    padding-top: 9px;
    float: right;
    display: flex;

    @media ${breakpoints.tablet} {
        width: 100%;
        flex-direction: column;
    }
`;

export default Card;
