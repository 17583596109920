import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import MainLayout from '../layouts/MainLayout';

import DividerLine from '../components/shared/DividerLine';
import img from '../assets/Images';
import Card from './../components/shared/Card';

const Payment: FunctionComponent = () => {
    return (
        <MainLayout>
            <Wrapper>
                <h1>Плащане</h1>

                <DividerLine />

                <p>Страницата в момента се обновява. Моля да ни извините.</p>
                {/* <Card
                    heading="Наложен платеж"
                    content={`Плащането се извършва при доставка на продуктите, заедно с цената за доставката. За по-големи поръчки изпращаме продуктите след получаване на авансово плащане по банков път.`}
                /> */}

                {false && (
                    <>
                        <Card
                            heading="Методи на плащане"
                            content={`За поръчки от онлайн магазина ни приемаме само плащания с карта. За да поръчате, можете безопасно и сигурно да използвате формата за плащания с карта в страница "Количка", след като успешно добавите продукти. За поръчки на едро приемаме плащания по банков път на следния IBAN: BG00XXXXXXXXXXX.`}
                        />

                        <Card
                            heading="Обработка на поръчката"
                            content={`Поръчката Ви влиза в процес на обработка единствено щом получим плащане. За поръчки на едро (над 1
                        палет) е нужно да заплатите депозит от 50% от цената за да започнем обработката на поръчката Ви и
                        последващият производствен процес, а останалите 50% заплащате при доставка на стоките.`}
                        />

                        <Card
                            heading="Отказване на поръчка"
                            content={`Отказването на поръчки, които са поръчани чрез онлайн магазина ни става на телефон
                    +359 878 240 059 и е възможно до момента, в който не Ви се е обадил
                    наш служител да потвърдите детайлите около поръчката. Обикновено това се случва между 24-48 часа
                    след получаване на плащането. Сумата ще Ви бъде възстановена до 7 работни дни.`}
                        />
                    </>
                )}

                <DividerLine />

                <img alt="cerrawall logo" src={img.logo} />
            </Wrapper>
        </MainLayout>
    );
};

const Wrapper = styled.div`
    h1 {
        text-align: center;
        padding-top: 20px;
    }

    h1,
    h3,
    h4 {
        font-weight: 100;
    }

    a {
        color: #d87667;
        font-weight: 400;
    }

    img {
        width: 100%;
        height: auto;
    }
`;

export default Payment;
