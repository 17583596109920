import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { IntlProvider } from 'react-intl';

import { configureStore } from './store';
import App from './App';
import TranslationsProvider from './components/TranslationsProvider';

import 'normalize.css';
import './scss/index.scss';

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        {/* <IntlProvider locale={i18nConfig.locale} messages={messages[i18nConfig.locale]} defaultLocale="en"> */}
        <TranslationsProvider>
            <App />
        </TranslationsProvider>
        {/* </IntlProvider> */}
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
