import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const MainLayout: FunctionComponent = ({ children }) => {
    return (
        <MainWrapper>
            <Navigation />
            <Content>{children}</Content>
            <MainFooter />
        </MainWrapper>
    );
};
const MainWrapper = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    min-height: 100vh;
`;

const Content = styled.div`
    max-width: 960px;
    margin: 80px auto 0 auto;
    padding: 0 10px;
    padding-bottom: 330px; // footer height + 30
`;

const MainFooter = styled(Footer)`
    margin-top: 30px;
`;

export default MainLayout;
