import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';

import PRODUCTS_LIST from 'src/services/productsList';

import ProductTile from 'src/components/products/ProductTile';
import { PrimaryButton } from 'src/components/shared/Buttons/PrimaryButton';

type Props = {
    exclude?: string;
    productsToShow?: number;
};

const getRandom = (arr: Array<any>, n: number, exclude: string | undefined) => {
    let result = new Array(n);
    let len = arr.length;
    let taken = new Array(len);

    if (n > len) {
        throw new RangeError('getRandom: more elements taken than available');
    }

    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }

    return result;
};

const RandomProducts: FunctionComponent<Props> = ({ exclude, productsToShow = 3 }) => {
    const onlyBricks = PRODUCTS_LIST.filter((prod) => prod.productType === 'brick');
    const products = getRandom(onlyBricks, productsToShow, exclude);

    const setEvent = () => {
        ReactGA.event({
            category: 'Random Products',
            action: 'Show more button hit',
        });
    };

    return (
        <>
            <Wrapper>
                {products.map((product) => {
                    return <ProductTile size={productsToShow} key={product.id} product={product} />;
                })}

                {/* {products.map((product) => {
                return <ProductTile size={3} price={product.price} id={product.id} thumbnail={product.tumb} />;
            })} */}
            </Wrapper>

            <AllProducts onClick={setEvent} as={NavLink} to="/products">
                Разгледайте всички продукти
            </AllProducts>
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
`;

const AllProducts = styled(PrimaryButton)`
    display: flex;
    width: 100%;
    margin-bottom: 30px;
`;

export default RandomProducts;
