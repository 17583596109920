import { CartState, CartActionTypes, ADD_TO_CART, ADD_QUANTITY, REMOVE_ITEM } from './types';

const initialState: any = [];

export const cartReducer = (state = initialState, action: CartActionTypes): CartState => {
    switch (action.type) {
        case ADD_TO_CART: {
            return [...state, action.payload];
        }
        case REMOVE_ITEM: {
            const { id } = action.payload;
            const cart = state.filter((el: any) => {
                return el.id !== id;
            });

            return cart;
        }
        case ADD_QUANTITY: {
            const { id, quantity, totalPrice } = action.payload;

            let cart = [];

            let hasThisItem = state.some((pr: any) => pr.id === id);

            if (state.length > 0) {
                if (hasThisItem) {
                    // update existing item
                    cart = state.map((product: any) => {
                        if (product.id === id) {
                            return {
                                ...product,
                                quantity: quantity,
                                totalPrice: totalPrice,
                            };
                        }

                        // rest
                        return product;
                    });

                    return cart;
                } else {
                    // add new item
                    return [...state, action.payload];
                }
            }

            // first element
            return [...state, action.payload];
        }
        default:
            return state;
    }
};
