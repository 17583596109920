import { getItem } from '../services/storage';

export const getPreferredLocale = () => {
    const timeZone = new Date().getTimezoneOffset();
    // This is a 'hack' to detect the bulgarian time zone in case the navigator language
    // by default is set to 'en-EN'

    const initLocale: string = timeZone === -180 ? 'bg' : navigator.language.split(/[-_]/)[0];
    // Check if language is set in local storage

    const locale = getItem('language') || initLocale;

    return locale;
};
