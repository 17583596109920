import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import MainLayout from '../layouts/MainLayout';

import Card from '../components/shared/Card';
import DividerLine from '../components/shared/DividerLine';
import img from './../assets/Images';

const Contacts: FunctionComponent = () => {
    const EMAIL = process.env.REACT_APP_EMAIL;
    const MAILTO_STRING_URL = `mailto:${EMAIL}`;

    return (
        <MainLayout>
            <Wrapper>
                <h1>Контакти</h1>

                <Card
                    content="При въроси относно информация за цени на едро, транспорт, поръчки, време за доставка, следене на
                    статуса на поръчката Ви, или при някакви други въпроси относно нашата дейност, можете да се свържете
                    с нас на посочените телефони и e-mail адреси."
                />

                <h3>
                    Телефон: <a href="tel:+359878240059">+359 878 240 059</a>
                </h3>

                <h3>
                    E-mail: <a href={MAILTO_STRING_URL}>{EMAIL}</a>
                </h3>

                <DividerLine />

                <img alt="cerrawall logo" src={img.logo} />
            </Wrapper>
        </MainLayout>
    );
};

const Wrapper = styled.div`
    h1 {
        text-align: center;
        padding-top: 20px;
    }

    h1,
    h3,
    h4 {
        font-weight: 100;
    }

    a {
        color: #d87667;
        font-weight: 400;
    }

    img {
        width: 100%;
        height: auto;
    }
`;

export default Contacts;
