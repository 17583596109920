import React, { useState, FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Route } from 'react-router-dom';
import styled from 'styled-components/macro';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

// @todo - delete reach listbox and menu

import { setItem } from '../services/storage';
import { setLanguage } from '../store/i18n/actions';
import { breakpoints } from '../config';
import { getPreferredLocale } from '../services/i18n';

import img from './../assets/Images';
import color from './../assets/Colors';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ setLanguage }, dispatch);

type Props = ReturnType<typeof mapDispatchToProps>;

const Navigation: FunctionComponent<Props> = ({ setLanguage }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [preferredLocale, setPreferredLocale] = useState(getPreferredLocale);

    const ALLOW_SHOPPING = process.env.REACT_APP_ALLOW_SHOPPING;
    const isShoppingAllowed = ALLOW_SHOPPING === 'true';

    const setNewLocale = (value: 'en' | 'de' | 'bg') => {
        setItem('language', value);
        setLanguage(value);
        setPreferredLocale(value);
    };

    return (
        <Route>
            <Wrapper>
                <MenuWrapper>
                    <Logo />
                    <MenuLinksWrapper showMenu={showMenu}>
                        <ListElement>
                            <Link as={NavLink} onClick={() => setShowMenu(!showMenu)} exact to="/">
                                Начало
                            </Link>
                        </ListElement>

                        <ListElement>
                            <Link as={NavLink} onClick={() => setShowMenu(!showMenu)} exact to="/products">
                                Продукти
                            </Link>
                        </ListElement>

                        <ListElement>
                            <Link as={NavLink} onClick={() => setShowMenu(!showMenu)} exact to="/about">
                                За нас
                            </Link>
                        </ListElement>

                        <ListElement>
                            <Link as={NavLink} onClick={() => setShowMenu(!showMenu)} exact to="/contacts">
                                Контакти
                            </Link>
                        </ListElement>

                        {isShoppingAllowed && (
                            <ListElement>
                                <Link as={NavLink} onClick={() => setShowMenu(!showMenu)} exact to="/cart">
                                    <CartText hasItems={true}>Количка</CartText>
                                </Link>
                            </ListElement>
                        )}

                        {false && (
                            <ListElement>
                                <LocaleSelect
                                    value={preferredLocale}
                                    onChange={(e: any) => setNewLocale(e.target.value)}
                                >
                                    <option value="en">EN</option>
                                    <option value="de">DE</option>
                                    <option value="bg">BG</option>
                                </LocaleSelect>
                            </ListElement>
                        )}
                    </MenuLinksWrapper>

                    <MenuOpener onClick={() => setShowMenu(!showMenu)}>...</MenuOpener>
                </MenuWrapper>
            </Wrapper>
        </Route>
    );
};

const Wrapper = styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    background: ${color.white};
    display: block;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 6px 0px;
    box-sizing: border-box;
    z-index: 100;

    @media ${breakpoints.tablet} {
        height: 80px;
    }
`;

const MenuWrapper = styled.div`
    position: relative;
    max-width: 1408px;
    height: 80px;
    margin: auto;
    padding: 0 10px;

    @media ${breakpoints.tablet} {
        height: 80px;
    }
`;

const MenuLinksWrapper = styled.ul<{ showMenu: boolean }>`
    position: relative;
    display: block;
    margin: 0;
    left: 0;
    float: right;

    @media ${breakpoints.tablet} {
        display: ${(props) => (props.showMenu ? 'block' : 'none')};;
        width: 100%;
        height calc(100vh - 80px);
        position: absolute;
        top: 80px;
        padding: 0;
        background: ${color.white};
    }
`;

const MenuOpener = styled.button`
    display: none;
    height: 0;

    @media ${breakpoints.tablet} {
        line-height: 54px;
        width: 44px;
        height: 55px;
        display: block;
        position: relative;
        float: right;
        font-size: 40px;
        color: ${color.lightGrey};
        cursor: pointer;
        background: none;
        outline: none;
        border: none;
    }
`;

const ListElement = styled.li`
    display: inline-block;
    // margin: 0 30px 0 0;
    height: 80px;
    line-height: 80px;
    text-align: center;

    &:last-child {
        margin-right: 0;
    }

    @media ${breakpoints.tablet} {
        width: 100%;
        cursor: pointer;
        margin: 0;
        padding: 0;
        height: auto;
        border-top: 1px solid ${color.lightGrey};

        &:last-child {
            margin-right: 0;
            border-bottom: 1px solid ${color.lightGrey};
        }
    }
`;

const Link = styled.a`
    color: ${color.darkGrey};
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 100;
    padding: 0 10px 10px 10px;

    &:hover {
        color: ${color.darkRed};
    }

    &.active {
        border-bottom: 1px solid ${color.lightGrey};
    }

    &.active:hover {
        border-bottom: 1px solid ${color.darkRed};
    }

    @media ${breakpoints.tablet} {
        display: block;
        position: relative;
        width: 100%;
        padding: 20px;
        line-height: 40px;
        background: ${color.white};
        text-align: center;

        &.active {
            border-bottom: none;
            padding-bottom: 20px;
        }
    }
`;
const Logo = styled.div`
    width: 115px;
    height: 50px;
    position: relative;
    display: block;
    margin: 0;
    float: left;
    top: 15px;
    background: #d0d0d0 url(${img.logo}) no-repeat;
    background-size: cover;
`;

const LocaleSelect = styled.select`
    background: none;
    border: 1px solid #bbb;
    color: #444;
    height: 38px;
    width: 58px;
    padding-left: 15px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 12px;
`;

const CartText = styled.span<{ hasItems: boolean }>`
    ${({ hasItems }) =>
        hasItems &&
        `
        font-weight: 500;
        color: ${color.darkRed};
    `}
`;

export default connect(null, mapDispatchToProps)(Navigation);
