import styled from 'styled-components/macro';
import { breakpoints } from '../../config';

const DividerLine = styled.div<{ showOnSmallScreen?: boolean }>`
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 9px #000;
    opacity: 0.3;
    margin: 30px auto;

    @media ${breakpoints.smallDesktop} {
        display: ${({ showOnSmallScreen }) =>
            showOnSmallScreen
                ? `
         block`
                : 'none'};
    }
`;

export default DividerLine;
