import styled from 'styled-components/macro';

export const BaseButton = styled.button`
    position: relative;
    display: inline-block;
    margin: 0;
    min-height: 65px;
    text-align: center;
    font-size: 18px;
    font-weight: 100;
    letter-spacing: 1.2px;
    background: white;
    text-decoration: none;
    overflow: hidden;
    border: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
`;
