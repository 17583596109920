export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const REMOVE_ITEM = 'REMOVE_ITEM';

export type CartState = any;

type AddToCartAction = {
    type: typeof ADD_TO_CART;
    payload: CartState;
};

type AddQuantityAction = {
    type: typeof ADD_QUANTITY;
    payload: CartState;
};

type RemoveItemAction = {
    type: typeof REMOVE_ITEM;
    payload: CartState;
};

export type CartActionTypes = AddToCartAction | AddQuantityAction | RemoveItemAction;
