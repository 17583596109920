import install from './icons/install.png';
import width from './icons/width.png';
import brick from './icons/brick.png';
import original from './icons/original.png';
import camera from './icons/camera.png';
import catalog from './icons/catalog.png';
import shop from './icons/shop.png';
import info from './icons/info.png';
import facebook from './icons/social/fb.png';
import instagram from './icons/social/instagram.png';
import recylce from './icons/recycle.png';

const icon = {
    install,
    width,
    brick,
    original,
    camera,
    catalog,
    shop,
    info,
    recylce,

    social: {
        facebook,
        instagram,
    },
};

export default icon;
