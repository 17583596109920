import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import ScrollToTop from './utils/ScrollToTop';

import Home from './pages/Home';
import Products from './pages/Products';
import ProductDetails from './pages/ProductDetails';
import Contacts from './pages/Contacts';
import About from './pages/About';
import Gallery from './pages/Gallery';
import Delivery from './pages/Delivery';
import Payment from './pages/Payment';
import Stores from './pages/Stores';
import TosAndPp from './pages/TosAndPP';

const App = () => {
    const history = createBrowserHistory();

    React.useEffect(() => {
        ReactGA.initialize('G-DVXNXB0Y12', {
            debug: true,
            titleCase: false,
        });
        ReactGA.pageview('/'); // Record a pageview for the given page
        history.listen((location) => {
            ReactGA.ga('set', 'checkProtocolTask', null); // Update current user state
            ReactGA.pageview(location.hash); // Record a pageview for the given page
        });
    }); //eslint-disable-line

    return (
        <Router basename="/">
            <ScrollToTop>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/products" component={Products} />
                    <Route exact path="/products/:productId" component={ProductDetails} />
                    <Route exact path="/products/:productId/photos" component={Gallery} />
                    <Route exact path="/contacts" component={Contacts} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/gallery" component={Gallery} />
                    <Route exact path="/delivery" component={Delivery} />
                    <Route exact path="/payment" component={Payment} />
                    <Route exact path="/stores" component={Stores} />
                    <Route exact path="/privacy-policy-and-terms-and-conditions" component={TosAndPp} />
                </Switch>
            </ScrollToTop>
        </Router>
    );
};

export default App;
