import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { breakpoints } from 'src/config';
import img from 'src/assets/Images';
import icon from 'src/assets/Icons';
import color from 'src/assets/Colors';

const HomeMenu = () => {
    return (
        <HomeMenuWrapper>
            {false ? (
                <>
                    <Tile13 as={NavLink} to="/gallery">
                        <TileContent>
                            <Background1></Background1>
                            <CameraIcon />
                            <TileTitle>Галерия</TileTitle>
                        </TileContent>
                    </Tile13>

                    <Tile23>
                        <TileContent>
                            <Background2 />
                            <CatalogIcon />
                            <TileTitle>Каталог</TileTitle>
                        </TileContent>
                    </Tile23>
                    <Tile23>
                        <TileContent>
                            <Background3 />
                            <ShopIcon />
                            <TileTitle>Магазини</TileTitle>
                        </TileContent>
                    </Tile23>

                    <Tile13>
                        <TileContent>
                            <Background4 />
                            <InfoIcon />
                            <TileTitle>Полезно</TileTitle>
                        </TileContent>
                    </Tile13>
                </>
            ) : (
                <>
                    <Tile13 as={NavLink} to="/gallery">
                        <TileContent>
                            <Background1></Background1>
                            <CameraIcon />
                            <TileTitle>Галерия</TileTitle>
                        </TileContent>
                    </Tile13>

                    <Tile23 as={NavLink} to="/stores">
                        <TileContent>
                            <Background3 />
                            <ShopIcon />
                            <TileTitle>Магазини</TileTitle>
                        </TileContent>
                    </Tile23>
                </>
            )}
        </HomeMenuWrapper>
    );
};

const HomeMenuWrapper = styled.div`
    width: 100%;
    margin: auto;
    // padding: calc(96% / 50);
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: space-between;
`;

const ResponsiveTile = styled.div`
    position: relative;
    height: 0;
    box-sizing: border-box;
    overflow: hidden;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 6px 0px;

    @media ${breakpoints.tablet} {
        width: 100%;
        margin-bottom: 12px;
        padding-bottom: 180px;
    }
`;
const Tile13 = styled(ResponsiveTile)`
    width: calc(96% / 3);
    padding-bottom: calc(96% / 4);
    margin-bottom: 4%;
`;

const Tile23 = styled(ResponsiveTile)`
    width: 64%;
    padding-bottom: calc(96% / 4);
    margin-bottom: calc(64% / 50);
`;

const TileContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    cursor: pointer;
    background: #111;

    &:hover {
        background: ${color.darkRed};
    }
`;

const BgTiles = styled.div`
    width: 100%;
    height: 100%;
    opacity: 0.4;
    position: absolute;
`;

const Background1 = styled(BgTiles)`
    background-image: url(${img.img1});
    background-position: 15%;
    background-size: cover;
`;

const Background2 = styled(BgTiles)`
    background-image: url(${img.front});
    background-position: 15%;
    background-size: cover;
`;

const Background3 = styled(BgTiles)`
    background-image: url(${img.city});
    background-position: 15%;
    background-size: cover;
`;

const Background4 = styled(BgTiles)`
    background-image: url(${img.idea});
    background-size: cover;
`;

const CameraIcon = styled.div`
    background: url(${icon.camera});
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: cover;
    z-index: 1;
`;

const CatalogIcon = styled.div`
    background: url(${icon.catalog});
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: cover;
    z-index: 1;
`;

const ShopIcon = styled.div`
    background: url(${icon.shop});
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: cover;
    z-index: 1;
`;

const InfoIcon = styled.div`
    background: url(${icon.info});
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: cover;
    z-index: 1;
`;

const TileTitle = styled.h2`
    color: #fff;
    z-index: 1;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 0;
`;

export default HomeMenu;
