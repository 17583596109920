// import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ADD_TO_CART, ADD_QUANTITY, REMOVE_ITEM, CartActionTypes } from './types';

import { AppState } from '../index';

export const addToCart = (id: string) => async (
    dispatch: ThunkDispatch<AppState, void, CartActionTypes>,
    getState: () => AppState
): Promise<void> => {
    const { cart } = getState();
    if (cart.some((prod: any) => prod.id !== id)) {
        dispatch({
            type: ADD_TO_CART,
            payload: {
                id,
            },
        });
    }
};

export const addQuantity = (id: string, quantity: any, totalPrice: number) => async (
    dispatch: ThunkDispatch<AppState, void, CartActionTypes>
): Promise<void> => {
    dispatch({
        type: ADD_QUANTITY,
        payload: {
            id,
            quantity,
            totalPrice: (quantity * totalPrice).toFixed(2),
        },
    });
};

export const removeItem = (id: string) => async (
    dispatch: ThunkDispatch<AppState, void, CartActionTypes>,
    getState: () => AppState
): Promise<void> => {
    const { cart } = getState();
    if (cart.some((prod: any) => prod.id === id)) {
        dispatch({
            type: REMOVE_ITEM,
            payload: {
                id,
            },
        });
    }
};
