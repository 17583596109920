import { BaseButton } from './BaseButton';
import styled from 'styled-components/macro';

import { breakpoints } from './../../../config';
// import color from './../../../assets/Colors';

export const PrimaryButton = styled(BaseButton)`
    color: white;
    background-color: #d34e4e;
    padding: 0 25px;

    &:hover {
        background: #a43d3d;
    }

    &:disabled {
        background: #111;
        cursor: disabled;
    }

    @media ${breakpoints.tablet} {
        width: 100%;
    }
`;
