import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import MainLayout from '../layouts/MainLayout';

import Card from '../components/shared/Card';
import img from './../assets/Images';

const About: FunctionComponent = () => {
    return (
        <MainLayout>
            <Wrapper>
                <h1>За нас</h1>

                <Card
                    content="Нашата основна дейност е производство на тънки глинени облицовъчни тухли, предназначени за
                    интериорно и екстериорно приложение. Нашите продукти по състав не се отличават от обикновените
                    облицовъчни тухли и при производството не използваме гипс, бетон или изкуствени оцветители, което
                    значително допринася за качеството на продукта и устойчивостта на цветовете във времето, независимо
                    от атмосферните условия."
                />
                <Card
                    content="Винаги за нас движещата сила са били красотата и качеството. Стремим се да създаваме продукти, които
                    с времето единствено могат да станат по-красиви. Недостига на такива продукти, както и очарованието,
                    които те будят в хората, както и в самите нас, ни накараха с времето да направим крачката от гаражен проект през 2004 г. към
                    производство, което да може да достигне до други ценители на естествената красота, които не искат да
                    правят компромиси, използвайки имитиращи продукти."
                />

                <img alt="cerrawall logo" src={img.logo} />
            </Wrapper>
        </MainLayout>
    );
};

const Wrapper = styled.div`
    h1 {
        padding-top: 30px;
        text-align: center;
    }

    p {
        line-height: 30px;
    }

    img {
        width: 100%;
        height: auto;
    }
`;

export default About;
