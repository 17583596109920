export const SET_LOCALE = 'SET_LOCALE';

export type AvailableLocales = 'en' | 'de' | 'bg';

export type TranslationsState = {
    locale: AvailableLocales;
    messages: any;
};

type SetLocaleAction = {
    type: typeof SET_LOCALE;
    payload: TranslationsState;
};

export type TranslationActionTypes = SetLocaleAction;
