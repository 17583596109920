import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { ProductsType } from 'src/services/productsList';
import { breakpoints } from 'src/config';
import img from 'src/assets/Images';

type Props = {
    size?: number | undefined;
    product: ProductsType;
};

const ProductTile: FunctionComponent<Props> = ({ product, size }) => {
    const { id, name, photos, thumbnail } = product;

    const thumb: string = thumbnail ? img[thumbnail] : photos[0];
    return (
        <Wrapper size={size} as={Link} to={`/products/${id}`}>
            <Content>
                <Name>{name}</Name>
                <Thumbnail thumbnail={thumb} />
            </Content>
        </Wrapper>
    );
};

const Content = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    vertical-align: middle;
`;

const Thumbnail = styled.div<{ thumbnail: string }>`
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    height: 62%;
    width: 90%;
    background: url(${({ thumbnail }) => thumbnail});
    background-size: cover;
`;

const Name = styled.div`
    width: 100%;
    height: 60px;
    text-align: center;
    color: #666;
    line-height: 60px;
    position: absolute;
    top: 0;

    @media ${breakpoints.tablet} {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
    }
`;

const Wrapper = styled.div<{ size: any }>`
    height: 0;
    position: relative;
    width: calc(96% / ${(props) => (props.size === 3 ? ' 3' : ' 2')});
    padding-bottom: calc(96% / ${(props) => (props.size === 3 ? ' 3' : ' 2')});
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-bottom: 4%;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px 0;
    overflow: hidden;
    cursor: pointer;
    background: #fff;

    &:hover,
    &:focus {
        box-shadow: rgba(0, 0, 0, 0.3) 1px 2px 4px 0;
    }
`;

export default ProductTile;
