const color = {
    main: 'rgba(238, 158, 146)',

    white: '#ffffff',
    lightGrey: '#bfbfbf',
    grey: '#a3a3a3',
    darkGrey: '#444',

    lightRed: '#ff6669',
    red: '#fc5b5b',
    darkRed: '#c60101',
    darkerRed: '#821f1f',

    lightBrown: '#ee985e',
    brown: '#b5642e',
};

export default color;
