import { BaseButton } from './BaseButton';
import styled from 'styled-components/macro';

import { breakpoints } from './../../../config';
import color from './../../../assets/Colors';

export const OutlineButton = styled(BaseButton)`
    background: none;
    border: 1px solid #333;
    padding: 20px;
    color: #333;

    &:hover {
        background: #666;
        border-color: #666;
        color: ${color.white};
    }

    @media ${breakpoints.tablet} {
        width: 100%;
    }
`;
