import React from 'react';
import { NavLink } from 'react-router-dom';
import { Route } from 'react-router-dom';
import styled from 'styled-components/macro';

import icon from './../assets/Icons';

import { breakpoints } from '../config';

const Navigation = () => {
    const year = new Date().getFullYear();

    const EMAIL = process.env.REACT_APP_EMAIL;
    const MAILTO_STRING_URL = `mailto:${EMAIL}`;

    return (
        <Route>
            <Wrapper>
                <MenuWrapper>
                    <Links>
                        <Link as={NavLink} to="/">
                            Начало
                        </Link>
                        <Link as={NavLink} to="/products">
                            Продукти
                        </Link>
                        <Link as={NavLink} to="/about">
                            За нас
                        </Link>
                        <Link as={NavLink} to="/contacts">
                            Контакти
                        </Link>
                    </Links>

                    <MiddleSection>
                        <Link as={NavLink} to="/delivery">
                            Доставка
                        </Link>
                        <Link as={NavLink} to="/payment">
                            Начин на плащане
                        </Link>
                        <Link as={NavLink} to="/stores">
                            Магазини
                        </Link>
                        <Link as={NavLink} to="/gallery">
                            Галерия
                        </Link>
                        {false && (
                            <Link as={NavLink} to="/privacy-policy-and-terms-and-conditions">
                                Поверителност и условия
                            </Link>
                        )}
                    </MiddleSection>

                    <Contacts>
                        <h3>Контакти</h3>
                        <p>
                            Телeфон:
                            <br /> <a href="tel:+359878240059">+359 878 240 059</a>
                        </p>
                        <p>
                            E-mail:
                            <br /> <Link href={MAILTO_STRING_URL}>{EMAIL}</Link>
                        </p>
                        <p>Cerrawall &copy; {year}</p>
                        <SocialWrapper>
                            <>
                                <FbIcon target="_blank" href="https://www.facebook.com/pg/cerrawall/" />{' '}
                                <InstaIcon target="_blank" href="https://www.instagram.com/cerrawall/" />
                            </>
                        </SocialWrapper>
                    </Contacts>
                </MenuWrapper>
            </Wrapper>
        </Route>
    );
};

const Wrapper = styled.footer`
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 300px;
    margin: 0;
    padding: 0;
    background: #444;

    @media ${breakpoints.smallDesktop} {
    }
`;

const MenuWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: 1024px;
    margin: auto;
    padding: 0 10px;
    height: 100%;
`;

const Links = styled.div`
    display: flex;
    flex-direction: column;

    @media ${breakpoints.smallDesktop} {
        display: none;
    }
`;

const Link = styled.a`
    color: #ddd;
    padding: 12px 0;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;

    &:hover {
        color: #fff;
    }

    @media ${breakpoints.smallDesktop} {
        font-size: 15px;
    }
`;

const MiddleSection = styled.div`
    display: flex;
    flex-direction: column;
    color: #eee;
    text-align: left;
`;

const Contacts = styled.div`
    color: #eee;
    text-align: right;
    font-weight: 100;

    a {
        color: #ddd;
        font-weight: 400;
    }

    @media ${breakpoints.smallDesktop} {
        font-size: 14px;
        text-align: left;
    }
`;

const SocialWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Icon = styled.a`
    width: 30px;
    height: 30px;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
`;

const FbIcon = styled(Icon)`
    content: url(${icon.social.facebook});
`;

const InstaIcon = styled(Icon)`
    content: url(${icon.social.instagram});
    margin-left: 15px;
`;

export default Navigation;
