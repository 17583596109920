import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';

import PRODUCT_LIST from '../services/productsList';

import img from './../assets/Images';
import { breakpoints } from 'src/config';

const defaultImages = [
    {
        original: img.front,
        // thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        original: img.img1,
        // thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
        original: img.gallery1,
        // thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
        original: img.gallery2,
        // thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
        original: img.gallery3,
        // thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    /*     {
        original: img.intro,
        thumbnail: 'https://picsum.photos/id/1019/250/150/',
        description: '',
    }, */
    {
        original: img.logo,
        // thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
];

const Gallery = () => {
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [images, setImages] = useState<any>(defaultImages);

    const { productId } = useParams();

    React.useEffect(() => {
        if (productId) {
            const product = PRODUCT_LIST.find((prod) => prod.id === productId);
            const photos = product!.photos.map((pic: any, index: number) => {
                return {
                    original: img[pic],
                    thumbnail: pic,
                };
            });

            setImages(photos);
        }
    }, [productId]);

    const imageRef = useRef<any>(null);
    const history = useHistory();

    const escFunction = React.useCallback(
        (event) => {
            if (event.keyCode === 27) {
                history.goBack();
            }
        },
        [history]
    );

    React.useEffect(() => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [escFunction]);

    return (
        <Wrapper>
            <BackButtonWrapper onClick={() => history.goBack()}>
                <CloseIcon>X</CloseIcon>
                <CloseText>Затвори</CloseText>
            </BackButtonWrapper>

            <BackgroundBlur currentIndex={currentIndex} images={images} />

            <GalleryWrapper>
                <ImageGallery
                    ref={imageRef}
                    showThumbnails={false}
                    onSlide={() => setCurrentIndex(imageRef.current.getCurrentIndex())}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    items={images}
                />
            </GalleryWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    overflow: hidden;
`;

const GalleryWrapper = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
    max-width: 1024px;
    margin: auto;
    min-height: 100vh;

    .image-gallery {
        margin: auto;
    }
`;

const BackgroundBlur = styled.div<{ currentIndex: number; images: any }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    // background: url(${({ currentIndex, images }) => images[currentIndex].original});
    background-size: cover;
    background-position: center center;
    // filter: blur(60px) grayscale(70%);
    // transform: scale(1.5);
    overflow: hidden;
`;

const BackButtonWrapper = styled.button`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    flex-direction: column;
    padding: 10px;
    text-shadow: 2px 2px 6px black, -2px -2px 10px black;
`;

const CloseText = styled.span`
    font-size: 16px;

    @media ${breakpoints.tablet} {
        font-size: 14px;
    }
`;

const CloseIcon = styled.span`
    font-size: 40px;

    @media ${breakpoints.tablet} {
        font-size: 24px;
    }
`;

export default Gallery;
