import { getPreferredLocale } from '../../services/i18n';

import { TranslationsState, TranslationActionTypes, SET_LOCALE } from './types';

import messages_en from '../../i18n/en.json';
import messages_de from '../../i18n/de.json';
import messages_bg from '../../i18n/bg.json';

const getPrefferedMessages = () => {
    let locale = getPreferredLocale();

    if (locale === 'en') {
        return messages_en;
    }

    if (locale === 'de') {
        return messages_de;
    }

    if (locale === 'bg') {
        return messages_bg;
    }
};

const initialState: TranslationsState = {
    locale: getPreferredLocale(),
    messages: getPrefferedMessages(),
};

export const translationsReducer = (state = initialState, action: TranslationActionTypes): TranslationsState => {
    switch (action.type) {
        case SET_LOCALE: {
            return {
                locale: action.payload.locale,
                messages: { ...action.payload.messages },
            };
        }
        default:
            return state;
    }
};
