type Size = {
    // in mm
    width: number;
    height: number;
    thickness: number;
};

type Details = {
    pcsPerBox: number;
    boxCoversWithJoint: number;
    size: Size;
    description: string;
    technicalDetails: string[];
    additionalInformation?: string[];
    canBeCombinedWith: string;
};

export type ProductsType = {
    id: string;
    name: string;
    productType: 'brick' | 'sample';
    color?: string[];
    surface?: string;
    tumb?: string;
    price: number;
    discount?: number;
    discountStartDate?: string;
    discountDurationInDays?: number;
    discountEndDate?: number;
    sampleId?: string;
    sampleOf?: string;
    photos: any;
    thumbnail: string;
    details?: Details;
    metadata?: string;
};

const PRODUCTS_LIST: ProductsType[] = [
    {
        id: '1713',
        name: 'Amsterdam',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 39.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 58,
            boxCoversWithJoint: 1,
            size: {
                width: 24.5,
                height: 5.8,
                thickness: 0.8,
            },
            description: `Класическият светло червен цвят прави този модел тухли подходящ е за всякакви жилищни пространства, заведения, хотели, фитнеси, офиси, публични пространства, както и за екстериорно приложение, като облицоване на къщи, сгради, огради, колони и други. Продуктът може да бъде умело съчетан с голямо разнообразие от модерни архитектурни и дизайнерски решения.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod01'],
        thumbnail: 'thumb01',
    },
    {
        id: '1504',
        name: 'Manchester',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 39.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 54,
            boxCoversWithJoint: 1,
            size: {
                width: 25,
                height: 6.3,
                thickness: 0.8,
            },
            description: `Това е един от най-здравите ни и устойчиви на атмосферните условия продукти, което го прави най-предпочитаният ни продукт за екстериорно приложение, както и за камини. Тези тухли са изпечени при температура от над 1200 градуса, като това е причината за здравината им.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod02'],
        thumbnail: 'thumb02',
    },
    {
        id: '1643',
        name: 'London',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 127.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 54,
            boxCoversWithJoint: 1,
            size: {
                width: 25,
                height: 6.3,
                thickness: 1.3,
            },
            description: `Този модел е един от най-желаните ни, но и един от най-редките ни модели. Клиентите ни предпочитат този продукт, понеже е естествена рециклирана тухла с дългогодишна история, от стари сгради, сред които има и такива с историческо значение, които може да датират от края на 19 век. Един чудесен продукт, с който можете да внесете и почувствате историята навсякъде.Подходящи са както за интериорно, така и за екстериорно приложение.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod04'],
        thumbnail: 'thumb04',
    },
    // dallas
    {
        id: '1297',
        name: 'New York',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 76.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 54,
            boxCoversWithJoint: 1,
            size: {
                width: 25,
                height: 6.3,
                thickness: 1.3,
            },
            description: `Този модел е един от най-желаните ни, но и един от най-редките ни модели. Клиентите ни предпочитат този продукт, понеже е естествена рециклирана тухла с дългогодишна история, от стари сгради, сред които има и такива с историческо значение, които може да датират от края на 19 век. Един чудесен продукт, с който можете да внесете и почувствате историята навсякъде.Подходящи са както за интериорно, така и за екстериорно приложение.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod03'],
        thumbnail: 'thumb03',
    },
    {
        id: '1069',
        name: 'Seattle',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 127.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 54,
            boxCoversWithJoint: 1,
            size: {
                width: 25,
                height: 6.3,
                thickness: 1.3,
            },
            description: `Този модел е един от най-желаните ни, но и един от най-редките ни модели. Клиентите ни предпочитат този продукт, понеже е естествена рециклирана тухла с дългогодишна история, от стари сгради, сред които има и такива с историческо значение, които може да датират от края на 19 век. Един чудесен продукт, с който можете да внесете и почувствате историята навсякъде.Подходящи са както за интериорно, така и за екстериорно приложение.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod05'],
        thumbnail: 'thumb05',
    },
    /* {
        id: '1863',
        name: 'Rustic',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 59.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 54,
            boxCoversWithJoint: 1,
            size: {
                width: 25,
                height: 6.3,
                thickness: 0.8,
            },
            description: `Този модел е един от най-желаните ни, но и един от най-редките ни модели. Клиентите ни предпочитат този продукт, понеже е естествена рециклирана тухла с дългогодишна история, от стари сгради, сред които има и такива с историческо значение, които може да датират от края на 19 век. Един чудесен продукт, с който можете да внесете и почувствате историята навсякъде.Подходящи са както за интериорно, така и за екстериорно приложение.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod06'],
        thumbnail: 'thumb06',
    }, */
    {
        id: '1684',
        name: 'Dallas',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 104.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 54,
            boxCoversWithJoint: 1,
            size: {
                width: 25,
                height: 6.3,
                thickness: 0.8,
            },
            description: `Този модел е един от най-желаните ни, но и един от най-редките ни модели. Клиентите ни предпочитат този продукт, понеже е естествена рециклирана тухла с дългогодишна история, от стари сгради, сред които има и такива с историческо значение, които може да датират от края на 19 век. Един чудесен продукт, с който можете да внесете и почувствате историята навсякъде.Подходящи са както за интериорно, така и за екстериорно приложение.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod07'],
        thumbnail: 'thumb07',
    },
    /* {
        id: '1823',
        name: 'Edinburgh',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 104.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 54,
            boxCoversWithJoint: 1,
            size: {
                width: 25,
                height: 6.3,
                thickness: 0.8,
            },
            description: `Този модел е един от най-желаните ни, но и един от най-редките ни модели. Клиентите ни предпочитат този продукт, понеже е естествена рециклирана тухла с дългогодишна история, от стари сгради, сред които има и такива с историческо значение, които може да датират от края на 19 век. Един чудесен продукт, с който можете да внесете и почувствате историята навсякъде.Подходящи са както за интериорно, така и за екстериорно приложение.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod08'],
        thumbnail: 'thumb08',
    }, */
    {
        id: '1180',
        name: 'Chicago',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 72.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 54,
            boxCoversWithJoint: 1,
            size: {
                width: 25,
                height: 6.3,
                thickness: 1.1,
            },
            description: `Този модел е един от най-желаните ни, но и един от най-редките ни модели. Клиентите ни предпочитат този продукт, понеже е естествена рециклирана тухла с дългогодишна история, от стари сгради, сред които има и такива с историческо значение, които може да датират от края на 19 век. Един чудесен продукт, с който можете да внесете и почувствате историята навсякъде.Подходящи са както за интериорно, така и за екстериорно приложение.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod09'],
        thumbnail: 'thumb09',
    },
    {
        id: '1298',
        name: 'Atlanta',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 124.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 54,
            boxCoversWithJoint: 1,
            size: {
                width: 25,
                height: 6.3,
                thickness: 1.1,
            },
            description: `Този модел е един от най-желаните ни, но и един от най-редките ни модели. Клиентите ни предпочитат този продукт, понеже е естествена рециклирана тухла с дългогодишна история, от стари сгради, сред които има и такива с историческо значение, които може да датират от края на 19 век. Един чудесен продукт, с който можете да внесете и почувствате историята навсякъде.Подходящи са както за интериорно, така и за екстериорно приложение.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod11'],
        thumbnail: 'thumb11',
    },
    {
        id: '1039',
        name: 'Boston',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 124.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 54,
            boxCoversWithJoint: 1,
            size: {
                width: 25,
                height: 6.3,
                thickness: 1.1,
            },
            description: `Този модел е един от най-желаните ни, но и един от най-редките ни модели. Клиентите ни предпочитат този продукт, понеже е естествена рециклирана тухла с дългогодишна история, от стари сгради, сред които има и такива с историческо значение, които може да датират от края на 19 век. Един чудесен продукт, с който можете да внесете и почувствате историята навсякъде.Подходящи са както за интериорно, така и за екстериорно приложение.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod10'],
        thumbnail: 'thumb10',
    },
    {
        id: '1065',
        name: 'Philadelphia',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 69.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 54,
            boxCoversWithJoint: 1,
            size: {
                width: 25,
                height: 6.3,
                thickness: 0.8,
            },
            description: `асдас`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod12'],
        thumbnail: 'thumb12',
    },
    {
        id: '1201',
        name: 'Sahara Gold',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 129.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 54,
            boxCoversWithJoint: 1,
            size: {
                width: 25,
                height: 6.3,
                thickness: 0.8,
            },
            description: `Този модел е един от най-желаните ни, но и един от най-редките ни модели. Клиентите ни предпочитат този продукт, понеже е естествена рециклирана тухла с дългогодишна история, от стари сгради, сред които има и такива с историческо значение, които може да датират от края на 19 век. Един чудесен продукт, с който можете да внесете и почувствате историята навсякъде.Подходящи са както за интериорно, така и за екстериорно приложение.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod13'],
        thumbnail: 'thumb13',
    },
    {
        id: '1753',
        name: 'Minneapolis',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 127.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 54,
            boxCoversWithJoint: 1,
            size: {
                width: 25,
                height: 6.3,
                thickness: 0.8,
            },
            description: `Този модел е един от най-желаните ни, но и един от най-редките ни модели. Клиентите ни предпочитат този продукт, понеже е естествена рециклирана тухла с дългогодишна история, от стари сгради, сред които има и такива с историческо значение, които може да датират от края на 19 век. Един чудесен продукт, с който можете да внесете и почувствате историята навсякъде.Подходящи са както за интериорно, така и за екстериорно приложение.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod14'],
        thumbnail: 'thumb14',
    },
    {
        id: '1198',
        name: 'Sunderland',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 72.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 52,
            boxCoversWithJoint: 1,
            size: {
                width: 25.5,
                height: 6.3,
                thickness: 0.8,
            },
            description: `Този модел е един от най-желаните ни, но и един от най-редките ни модели. Клиентите ни предпочитат този продукт, понеже е естествена рециклирана тухла с дългогодишна история, от стари сгради, сред които има и такива с историческо значение, които може да датират от края на 19 век. Един чудесен продукт, с който можете да внесете и почувствате историята навсякъде.Подходящи са както за интериорно, така и за екстериорно приложение.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod16'],
        thumbnail: 'thumb16',
    },
    {
        id: '1117',
        name: 'Sierra',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 124.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 52,
            boxCoversWithJoint: 1,
            size: {
                width: 25.5,
                height: 6.3,
                thickness: 0.8,
            },
            description: `Този модел е един от най-желаните ни, но и един от най-редките ни модели. Клиентите ни предпочитат този продукт, понеже е естествена рециклирана тухла с дългогодишна история, от стари сгради, сред които има и такива с историческо значение, които може да датират от края на 19 век. Един чудесен продукт, с който можете да внесете и почувствате историята навсякъде.Подходящи са както за интериорно, така и за екстериорно приложение.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod17'],
        thumbnail: 'thumb17',
    },
    /*     {
        id: '1084',
        name: 'Canberra',
        productType: 'brick',
        color: ['red'],
        surface: 'smooth',
        price: 124.99,
        discount: 20,
        discountStartDate: '31 oct 2020',
        discountDurationInDays: 16,
        sampleId: '864001',
        details: {
            pcsPerBox: 52,
            boxCoversWithJoint: 1,
            size: {
                width: 25.5,
                height: 6.3,
                thickness: 0.8,
            },
            description: `Този модел е един от най-желаните ни, но и един от най-редките ни модели. Клиентите ни предпочитат този продукт, понеже е естествена рециклирана тухла с дългогодишна история, от стари сгради, сред които има и такива с историческо значение, които може да датират от края на 19 век. Един чудесен продукт, с който можете да внесете и почувствате историята навсякъде.Подходящи са както за интериорно, така и за екстериорно приложение.`,

            technicalDetails: [`Няма данни`],
            additionalInformation: [
                'Обикновено тухлите се произвеждат по заявка и се доставят между 7-15 работни дни след получаване на заявката.',
            ],
            canBeCombinedWith: '1',
        },
        metadata: 'сръбски',
        photos: ['prod15'],
        thumbnail: 'thumb15',
    }, */

    // SAMPLES
    {
        id: '864001',
        name: 'Класическа тухла /МОСТРА/',
        productType: 'sample',
        price: 19.99,
        sampleOf: '0',
        photos: ['https://brickit.com/images/product_images/191/milwaukeeusedsampleboardmod__small.png'],
        thumbnail: 'thumb01',
    },
];

export default PRODUCTS_LIST;
